//api 接口处理
import request from '@/utils/request.js'
export default {
    //登录··
    login (param) {
        return request.post('/api/user/login', param)
    },
    //注册··
    register (param) {
        return request.post('/api/user/register', param)
    },
    //修改密码
    passwordInfo (param) {
        return request.post('/api/user/reset_password', param, false, true)
    },
    // 个人信息
    myself (param) {
        return request.get('/api/user/view_self', param, false, true)
    },
    // 个人身份选择
    selectShenf (param) {
        return request.post('/api/user/set_type', param, false, true)
    },
    // 编辑个人信息
    myselfInfo (param) {
        return request.post('/api/user/store', param, false, true)
    },
    // 主页  个人/企业/高校 信息
    schoolInfo (param) {
        return request.get('/api/college/view_self', param, false, true)
    },
    //主页  高校信息保存
    storeSchool (param) {
        return request.post('/api/college/store_self', param, false, true)
    },
    //主页  企业信息保存
    storeQiye (param) {
        return request.post('/api/enterprise/store_self', param, false, true)
    },


    //主页 我的收藏列表
    cangList (param) {
        return request.get('/api/collect/list', param, false, true)
    },
    // 主页 高校名称判断是否重名
    isSchoolName (param) {
        return request.get('/api/college/check_college_name', param, false, true)
    },


    // 省列表
    getProList (param) {
        return request.get('/api/region/getProvince', param)
    },
    // 市列表
    getCityList (param) {
        return request.get('/api/region/getCity', param)
    },
    // 区列表
    getTownList (param) {
        return request.get('/api/region/getTown', param)
    },
    // 省市区树形下拉
    getAllarea (param) {
        return request.get('/api/region/get_level_data', param)
    },




    // 高校下拉
    getSchoolSelect (param) {
        return request.get('/api/college/select_college', param, false, true)
    },
    getSchoolCheck (param) {
        return request.get('/api/college/check_college_name', param, false, true)
    },




    //首页热门高校列表
    getCollegeList (param) {
        return request.get('/api/college/hot_college', param)
    },
    //首页最新成果列表
    getAchievementList (param) {
        return request.get('/api/achievement/hot_achievement', param)
    },
    //首页需求展示列表
    getRequirementList (param) {
        return request.get('/api/requirement/hot_requirement', param)
    },
    //科技政策列表
    getPolocyList (param) {
        return request.get('/api/policy/list', param)
    },

    //高校列表
    getSchoolList (param) {
        return request.get('/api/college/list', param)
    },
    // 高校详情
    schoolDetail (param) {
        return request.get('/api/college/view', param)
    },

    //科技成果列表
    getChenguoList (param) {
        return request.get('/api/achievement/list', param)
    },
    //科技成果列表(个人主页  需带token)
    getMyChenguoList (param) {
        return request.get('/api/achievement/list', param, false, true)
    },
    //科技成果详情
    viewGuo (param) {
        return request.get('/api/achievement/view', param)
    },
    //科技成果删除
    delGuo (param) {
        return request.delete('/api/achievement/delete', param, false, true)
    },
    //成果物保存发布
    addGuo (param) {
        return request.post('/api/achievement/store', param, false, true)
    },
    // 查看自己企业信息
    viewSelf (param) {
        return request.get('/api/enterprise/view_self', param, false, true)
    },




    //企业需求列表
    getNeedList (param) {
        return request.get('/api/requirement/list', param)
    },
    //企业需求详情
    viewNeed (param) {
        return request.get('/api/requirement/view', param)
    },
    // 需求发布
    addNeed (param) {
        return request.post('/api/requirement/store', param, false, true)
    },
    // 需求发布附件删除
    delNeed (param) {
        return request.delete('/api/requirement/delete_file', param, false, true)
    },
    //个人主页需求列表删除
    delNeedList (param) {
        return request.delete('/api/requirement/delete', param, false, true)
    },



    // 政策资讯列表
    getPolicyList (param) {
        return request.get('/api/policy/list', param)
    },
    //政策资讯详情
    getPolicyDetail (param) {
        return request.get('/api/policy/view', param)
    },

    // 判断是否收藏 1高校，2科技成果，3企业需求
    isCang (param) {
        return request.get('/api/collect/check_collect', param, false, true)
    },
    // 添加收藏 
    addCang (param) {
        return request.post('/api/collect/store', param, false, true)
    },
    // 取消收藏 
    delCang (param) {
        return request.delete('/api/collect/delete', param, false, true)
    },
    // 高校详情-成果物列表
    schoolGuoList (param) {
        return request.get('/api/achievement/list', param)
    },



    // 关于我们-在线留言
    getMessage (param) {
        return request.post('/api/message/store_message', param)
    },


    // 首页合作板块数据
    getHzData (param) {
        return request.get('/api/website/get_data_show', param)
    },



    // 获取手机验证码
    telCode (param) {
        return request.get('/api/message/send_message', param)
    },
}