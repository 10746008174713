import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import config from './config/index.js'
//test
import content from './config/content.js'
import api from './api/index.js'
import * as commonFun from './utils/function.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'


import axios from 'axios'
Vue.prototype.axios = axios;
Vue.use(ElementUI)

//$apiPath
import isPath from '@/config/index.js'
Vue.prototype.$apiPath = isPath.request_url


Vue.config.productionTip = false
Vue.prototype.$config = config
Vue.prototype.$api = api
//test
Vue.prototype.$content = content
Vue.prototype.$commonFun = commonFun

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')