<template>
    <div>
        <el-container>
            <el-header class="header" id="head">
                <div ref="backTop" id="head_fff">
                    <div class="header_box">
                        <div class="logo_flex">
                            <img id="logo_img" @click="clickLogo()" :src="logoImg" alt="科转邦" />
                            <h1 id="head_title" class="head_title" v-if="$route.path == '/index'" :class="scrollnum > 0 ? 'black_title' : 'fff_title'
                                ">
                                <span style="
                                        font-weight: normal;
                                        display: inline-block;
                                        margin-bottom: 0.3125rem;
                                    ">| &nbsp;
                                </span>
                                高校院所科技成果转化对接平台
                            </h1>
                            <h1 id="head_title" class="head_title" v-if="$route.path !== '/index'">
                                <span style="
                                        font-weight: normal;
                                        display: inline-block;
                                        margin-bottom: 0.3125rem;
                                    ">| &nbsp; </span>高校院所科技成果转化对接平台
                            </h1>
                        </div>
                        <div class="header_link">
                            <el-menu id="menu_demo" :background-color="menuBack" :text-color="textColor"
                                :active-text-color="activeText" :default-active="activeIndex" mode="horizontal">
                                <el-menu-item @click="go_http(v)" :index="v.route" v-for="(v, index) in routers"
                                    :key="index">{{ v.name }}</el-menu-item>
                            </el-menu>
                            <el-button :class="$route.path == '/index'
                                ? 'btnrgb'
                                : 'btnblue'
                                " v-if="loginShow" @click="goLogin()" style="border: none; margin-left: 3.125rem"
                                id="login" type="primary">登录 | 注册</el-button>
                            <!-- 个人头像昵称下拉 -->
                            <el-dropdown trigger="click" v-if="myShow">
                                <div class="avatar">
                                    <span id="myname">{{ myName }}</span>
                                    <el-avatar :size="35" :src="myAvatar"></el-avatar>
                                </div>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="myHome()">我的主页</el-dropdown-item>
                                    <el-dropdown-item @click.native="outLogin()">退出账号</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                </div>
            </el-header>
            <el-main>
                <router-view />
            </el-main>
            <el-footer>
                <div class="foot_content">
                    <div class="foot_flex">
                        <div class="tel">
                            <h1>高校院所科技成果转化对接平台</h1>
                            <div class="tel_flex">
                                <img src="@/assets/image/icon4.png" alt />
                                <span>158-6230 8950</span>
                            </div>
                        </div>
                        <div class="email">
                            <span>联系方式</span>
                            <div style="margin-bottom: 1.5625rem">
                                <i style="margin-right: 0.9375rem" class="el-icon-message"></i>
                                280555794@qq.com
                            </div>
                            <div>
                                <i style="margin-right: 0.9375rem" class="el-icon-s-promotion"></i>
                                苏州高新区竹园路209号2号楼1004室
                            </div>
                        </div>
                    </div>
                    <div class="link">
                        <span style="margin-right: 1.875rem">友情链接</span>
                        <a href="" target="_blank">高新技术企业认定工作网</a>
                        <a href="https://www.most.gov.cn/index.html" target="_blank">中国科学技术部</a>
                        <a href="https://www.mof.gov.cn/index.htm" target="_blank">国家财政部</a>
                        <a href="https://www.chinatax.gov.cn/" target="_blank">国家税务总局</a>
                        <a href="https://www.chinatorch.org.cn/platform/f/home/index" target="_blank">科技型中小企业服务</a>
                        <a href="https://www.miit.gov.cn/" target="_blank">中国工业和信息化部</a>
                        <a href="https://www.samr.gov.cn/" target="_blank">国家市场监督管理总局</a>
                        <a href="https://www.cnipa.gov.cn/" target="_blank">国家知识产权局</a>
                        <a href="https://www.ccopyright.com.cn/" target="_blank">中国版权保护中心</a>
                    </div>
                    <div class="yuyan">
                        <span>语言</span>
                        <span style="margin-left: 5.625rem; color: #919191">简体中文</span>
                    </div>
                    <span class="foot_copy">Copyright &#169; 版权所有：科技成果转化平台 <a style="color: #fff;text-decoration: none;"
                            href="http://www.kezhuanbang.com" target="_blank">http://www.kezhuanbang.com</a>
                        网站备案：苏ICP备2024106823号</span>
                </div>
            </el-footer>
        </el-container>
        <el-dialog class="shenf_box" :show-close="false" :visible.sync="dialogVisible" width="30.9375rem">
            <div class="shenf">
                <h1>请选择身份</h1>
                <span>SELECT IDENTITY</span>
            </div>
            <p>为了更好的使用体验，请谨慎选择</p>
            <div class="select_hover" style="margin-top: 1.875rem">
                <div class="select_box" style="background: #329dff" @click="selectType('4')">
                    <div>
                        <h2>我是个人</h2>
                        <span>查高校，查科技成果物，查需求</span>
                    </div>
                    <img src="@/assets/image/icon17.png" alt="" />
                </div>
            </div>
            <div class="select_hover">
                <div class="select_box" style="background: #ff5a5a" @click="selectType('2')">
                    <div>
                        <h2>我是高校</h2>
                        <span>发布科技成果物，查需求，查政策</span>
                    </div>
                    <img src="@/assets/image/icon18.png" alt="" />
                </div>
            </div>
            <div class="select_hover">
                <div class="select_box" style="background: #4868ff" @click="selectType('3')">
                    <div>
                        <h2>我是企业</h2>
                        <span>发布需求，查科技成果物，查政策</span>
                    </div>
                    <img src="@/assets/image/icon19.png" alt="" />
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// import apiPath from '@/config/index.js'
export default {
    data () {
        return {
            // apiPath,
            isLogin: false,
            menuBack: 'transparent', //菜单背景色默认透明
            logoImg: require('@/assets/image/logo_white.png'),
            dialogVisible: false,
            myAvatar: '',
            myName: '',
            loginShow: true,
            myShow: false,
            activeText: '#fff',
            textColor: '#CACACA',
            // logo_fff: require('@/assets/image/logo_white.png'),
            // logo_blue: require('@/assets/image/logo_blue.png'),
            scrollnum: '',
            activeIndex: this.$route.path,
            routers: [
                {
                    route: '/index',
                    name: '首页',
                },
                {
                    route: '/school',
                    name: '高校院所',
                },
                {
                    route: '/achievement',
                    name: '科技成果',
                },
                {
                    route: '/release',
                    name: '企业需求',
                },
                {
                    route: '/policy',
                    name: '政策资讯',
                },
                {
                    route: '/we',
                    name: '关于我们',
                },
            ],
        }
    },
    created () {
        this.getMyself()
        if (localStorage.getItem('token')) {
            this.loginShow = false
            this.myShow = true
        } else {
            this.loginShow = true
            this.myShow = false
        }
    },
    mounted () {
        this.handleScroll()
        // 滚动条的获取
        window.addEventListener('scroll', this.handleScroll, true)
    },
    watch: {
        // 监听路由对象 $route 的变化
        '$route': {
            handler: function (to, from) {
                this.activeIndex = to.path
                // 路由变化时的处理逻辑
                this.handleScroll()
            },
            // 设置为深度监听
            deep: true
        }
    },
    methods: {
        selectType (val) {
            console.log(val)
            this.$api.selectShenf({ type: val }).then((res) => {
                this.$message({
                    message: '选择完成',
                    type: 'success',
                })
                this.dialogVisible = false
            })
        },
        getMyself () {
            this.$api.myself().then((res) => {
                this.myName = res.name
                this.isLogin = true
                if (!res.name) {
                    this.myName = '科转邦用户'
                }
                this.myAvatar = this.$apiPath + res.avatar_url
                if (!res.type) {
                    this.dialogVisible = true
                } else {
                    this.dialogVisible = false
                }
            })
        },
        myHome () {
            this.activeIndex = ''
            this.$router.push('/user')
        },
        outLogin () {
            //删除本地token
            localStorage.clear('token')
            this.loginShow = true
            this.myShow = false
            this.isLogin = false
            this.$router.push('/index')
            this.activeIndex = '/index'
            if (this.activeIndex == '/index') {
                this.$router.go(0)
            }
            this.$message({
                message: '退出登录',
                type: 'success',
            })
        },
        go_http (v) {
            this.$router.push(v.route)
            var color = document.getElementById('head_fff')
            let name = document.getElementById('myname')
            let menuBac = document.getElementById('menu_demo')
            let login = document.getElementById('login')
            if (v.route !== '/index') {
                // background: rgba(255,255,255,0.11);
                color.style.background = '#fff'
                this.logoImg = require('@/assets/image/logo_blue.png')
                // title.style.borderColor = '#D5D5D5'
                this.textColor = '#000'
                this.activeText = '#0542E8'
                if (this.myShow) {
                    name.style.color = '#000'
                } else if (this.loginShow) {
                    login.style.background = '#0542E8'
                }
            } else {
                this.logoImg = require('@/assets/image/logo_white.png')
                this.textColor = '#D5D5D5'
                color.style.background = 'none'
                menuBac.style.background = 'none'
                this.activeText = '#fff'
                if (this.myShow) {
                    name.style.color = '#fff'
                } else if (this.loginShow) {
                    login.style.background = 'rgba(255,255,255,0.11)'
                }
            }
        },
        goLogin () {
            this.$router.push('/login')
            // if (this.activeIndex == '/index') return
            // this.$router.push('/index')
            // this.activeIndex = '/index'
        },
        handleScroll () {
            this.scrollnum = window.pageYOffset
            var color = document.getElementById('head_fff')
            let name = document.getElementById('myname')
            let login = document.getElementById('login')

            if (this.$route.path == '/index') {
                if (this.scrollnum == 0) {
                    if (this.myShow) {
                        name.style.color = '#fff'
                    } else {
                        login.style.background = 'rgba(255,255,255,0.11)'
                    }
                    this.logoImg = require('@/assets/image/logo_white.png')
                    // 头部背景色
                    color.style.background = 'none'
                    // 头部阴影
                    color.style.boxShadow = 'none'
                    // 动画时间
                    color.style.transition = '.5s' + ' ' + 'all'
                    // indexColor.style.backgroundColor = 'none'
                    // indexColor.style.transition = '1s' + ' ' + 'all'
                    this.textColor = '#fff'
                    this.activeText = '#D5D5D5'
                } else {
                    color.style.background = '#fff'
                    this.logoImg = require('@/assets/image/logo_blue.png')
                    this.textColor = '#000'
                    this.activeText = '#0542E8'
                    if (this.myShow) {
                        name.style.color = '#000'
                    } else if (this.loginShow) {
                        login.style.background = '#0542E8'
                    }
                    // bor.style.border = '3px solid #0542E8' + ' ' + '!important'
                    // console.log(bor)
                }
            } else {
                color.style.background = '#fff'
                this.logoImg = require('@/assets/image/logo_blue.png')
                this.textColor = '#000'
                this.activeText = '#0542E8'
                if (this.myShow) {
                    name.style.color = '#000'
                } else if (this.loginShow) {
                    login.style.background = '#0542E8'
                }
            }
        },
    },
}
</script>

<style scoped lang="scss">
.btnrgb {
    background: rgba(255, 255, 255, 0.11);
}

.btnblue {
    background: #0542e8;
}

:deep(.el-dialog__body) {
    padding: 2.875rem;
    border-radius: 0.5rem;
}

.select_hover {
    padding: 0.75rem;
    background-color: #fff;
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.select_hover:hover {
    background-color: #edf2ff;
}

.shenf_box {
    .select_box {
        width: 19.625rem;
        padding: 1.8125rem 2.5rem;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 0.5rem;
        cursor: pointer;

        img {
            width: 3.25rem;
            height: 3.25rem;
        }

        h2 {
            font-size: 1.125rem;
            font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        }

        span {
            font-size: 0.75rem;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        }
    }

    .shenf {
        display: flex;
        align-items: center;
        color: #000;

        h1,
        span {
            font-size: 1.5rem;
        }

        h1 {
            margin-right: 0.625rem;
        }
    }

    p {
        font-size: 0.75rem;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: Regular;
    }
}

#myname {
    color: #fff;
}

:deep(.el-dropdown-menu__item:not(.is-disabled):hover) {
    color: #0542e8;
}

.avatar {
    display: flex;
    align-items: center;
    margin-left: 3.75rem;
    cursor: pointer;

    span {
        margin-right: 0.625rem;
        font-size: 0.9375rem;
        color: #000;
    }
}

// .header {
//   background: #fff;
// }
:deep(.el-menu--horizontal > .el-menu-item) {
    margin: 0 1.25rem !important;
}

.indexMenu {
    background: none;
}

.pathMenu {
    background: #fff !important;
}

.fff_title {
    color: #fff !important;
}

.black_title {
    color: #000;
}

.logo_flex {
    height: 3.9375rem;
    display: flex;
    align-items: center;

    #logo_img {
        width: 5.1875rem;
    }

    h1 {
        padding-left: 1.0625rem;
    }
}

.header_link {
    display: flex;
    align-items: center;
}

.el-menu-demo {
    background: none;
}

.el-menu-demoB {
    background: #fff;
}

.header_box {
    height: 100%;
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.yuyan {
    font-size: 0.75rem;
    margin: 1rem 0 1.25rem 0;
}

.foot_copy {
    font-size: 0.75rem;
}

.link {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    margin-top: 3.75rem;
    border-top: 0.0625rem solid #454545;
    border-bottom: 0.0625rem solid #454545;
    padding: 0.5625rem 0;

    a {
        color: #fff;
        text-decoration: none;
    }
}

.foot_flex {
    display: flex;

    .email {
        margin-left: 3.4375rem;
        margin-top: 0.4375rem;
        font-size: 0.875rem;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: Regular;

        span {
            display: block;
            margin-bottom: 2.1875rem;
        }
    }
}

.el-menu--horizontal>.el-menu-item {
    padding: 0;
    margin: 0 1.875rem;
}

:deep(.el-menu.el-menu--horizontal) {
    border-bottom: none !important;
}

.header {
    width: 100%;
    height: 3.9375rem !important;
    padding: 0;
    position: fixed;
    /*将导航栏设置为固定位置*/
    top: 0;
    /*距顶部的距离*/
    z-index: 99;
}

.el-menu--horizontal>.el-menu-item {
    border-bottom: none;
}

.el-menu-item:focus,
.el-menu-item:hover {
    background: none !important;
    border-bottom: none !important;
}

.is-active {
    border-top: 0.1875rem solid #fff;
    border-bottom: none !important;
    line-height: 400% !important;
}

:deep(.el-footer) {
    width: 100%;
    height: 22.875rem !important;
    background: #2d2e31;
    overflow: hidden;

    .foot_content {
        width: 80rem;
        height: 100%;
        margin: 0 auto;
        color: #fff;
        margin-top: 2.8125rem;

        .tel {
            width: 21.875rem;
            height: 7.625rem;
            border-right: 0.0625rem solid #454545;

            h1 {
                font-size: 24px;
                font-family: PangMenZhengDao, PangMenZhengDao-Regular;
                font-weight: Regular;
            }

            .tel_flex {
                width: 60%;
                padding: 0.3125rem 0;
                background: #454545;
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin-top: 2.375rem;

                span {
                    font-size: 15px;
                    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                    font-weight: Regular;
                }

                img {
                    width: 1.0625rem;
                    height: 1.0625rem;
                }
            }
        }
    }
}
</style>
