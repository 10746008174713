const content = {
    // 高校类型
    schoolType: [{
        title: '高校',
        id: 1,
    },
    {
        title: '院所',
        id: 2,
    },
    ],
    // 院校类别
    schoolCategory: [{
        title: '综合类',
        id: 1,
    },
    {
        title: '理工类',
        id: 2,
    },
    {
        title: '农业类',
        id: 3,
    },
    {
        title: '林业类',
        id: 4,
    },
    {
        title: '医药类',
        id: 5,
    },
    {
        title: '师范类',
        id: 6,
    },
    {
        title: '语言类',
        id: 7,
    },
    {
        title: '财经类',
        id: 8,
    },
    {
        title: '政法类',
        id: 9,
    },
    {
        title: '体育类',
        id: 10,
    },
    {
        title: '艺术类',
        id: 11,
    },
    {
        title: '民族类',
        id: 12,
    },
    {
        title: '军事类',
        id: 13,
    },
    {
        title: '其他类',
        id: 14,
    },
    ],
    // 院校特色
    schoolCharacteristic: [{
        title: '985',
        id: 1,
    },
    {
        title: '211',
        id: 2,
    },
    {
        title: '双一流',
        id: 3,
    },
    {
        title: '强基计划',
        id: 4,
    },
    {
        title: '双高计划',
        id: 5,
    },
    {
        title: '普通本科',
        id: 6,
    },
    {
        title: '大专',
        id: 7,
    },
    ],
    // 办学类型
    typeOfEducation: [{
        title: '公办',
        id: 1,
    },
    {
        title: '民办',
        id: 2,
    },
    ],


    // 企业需求类型选择
    releaseType: [{
        title: '电子信息技术',
        id: 1,
    },
    {
        title: '生物与新医药技术',
        id: 2,
    },
    {
        title: '航空航天技术',
        id: 3,
    },
    {
        title: '新材料技术',
        id: 4,
    },
    {
        title: '高新技术服务业及公共事业',
        id: 5,
    },
    {
        title: '新能源及节能技术',
        id: 6,
    },
    {
        title: '资源与环境工程技术',
        id: 7,
    },
    {
        title: '高端装备与先进制造',
        id: 8,
    },
    {
        title: '化学与化学工程技术',
        id: 9,
    },
    {
        title: '现代农业与食品产业技术',
        id: 10,
    },
    {
        title: '其他',
        id: 11,
    }
    ]
}
export default content