<template>
    <div>
        <router-view />
    </div>
</template>
<script>
; (function (win) {
    var doc = win.document
    var docEl = doc.documentElement
    var tid
    function refreshRem () {
        var width = docEl.getBoundingClientRect().width
        // console.log('视窗宽度')
        // console.log(width)
        // if (width > 640) {// 最大宽度
        //   width = 640;
        // }
        if (width < 1600) {
            docEl.style.fontSize = '12px'
        } else {
            docEl.style.fontSize = '16px'
        }

        // 当显示屏分辨率为1920*1080，且UI设计图尺寸为1920*1080
        // 可设置根元素大小为10px，如下：
        // docEl.style.fontSize = width / 192
        // 实际使用：字体大小16px，为1.6rem
    }

    win.addEventListener(
        'resize',
        function () {
            //当调整浏览器窗口大小时，发生 resize 事件。
            // console.log('浏览器窗口大小发生变化resize~~')
            this.clearTimeout(tid)
            tid = this.setTimeout(refreshRem, 300)
        },
        false
    )
    win.addEventListener(
        'pageshow',
        function (e) {
            //事件在每次加载页面时触发,类似于 onload 事件
            // console.log('页面加载事件pageshow~~')
            if (e.persisted) {
                this.clearTimeout(tid)
                tid = this.setTimeout(refreshRem, 300)
            }
        },
        false
    )

    refreshRem()
})(window)
</script>

<style lang="scss">
@import './assets/css/config.scss';

* {
    margin: 0;
    padding: 0;
}

.el-main {
    padding: 0 !important;
}

.el-footer {
    padding: 0 !important;
}

.cg_title {
    margin: 50px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        margin: 0 12px;
        font-size: 22px;
    }

    .dian {
        width: 8px;
        height: 8px;
        background: #b6b6b6;
        border-radius: 50%;
    }
}

.english {
    display: block;
    width: 100%;
    font-size: 10px;
    font-family: Microsoft YaHei, Microsoft YaHei-Bold;
    font-weight: Bold;
    text-align: center;
    color: #b6b6b6;
}
</style>
