// http 请求
import config from '../config/index.js'
import * as commonFun from './function.js'
import axios from 'axios'
import store from '../store'
let { request_url, request_timeout } = config
let { goLogin, toast, loading, synchroStorage } = commonFun

    ;[
        'options',
        'get',
        'post',
        'put',
        'head',
        'delete',
        'trace',
        'connect',
    ].forEach((method) => {
        request[method] = (url, data = {}, loading, auth) => {
            if (auth) {
                data.token = localStorage.getItem('token')
            }

            if (method == 'get' && Object.keys(data).length) {
                url +=
                    '?' +
                    Object.keys(data).reduce((pre, cur, index) => {
                        return (
                            pre +
                            cur +
                            '=' +
                            data[cur] +
                            (index == Object.keys(data).length - 1 ? '' : '&')
                        )
                    }, '')
            }
            return request({
                url: url,
                param: data ? data : {},
                method: method,
                loading: loading ? true : false,
                auth: auth ? true : false,
            })
        }
    })

let requestList = [] //等待请求列表
let startRequest = false //是否在执行请求中
let openRefresh = false // 是否开启等待

export default function request (data) {
    return new Promise(async (resolve, reject) => {
        let param = {
            data: data,
            resolve: resolve,
            reject: reject,
        }
        data.param.refreshRequest
            ? requestList.unshift(param)
            : requestList.push(param)
        if (!startRequest || data.param.refreshRequest) recursionRequest()
    })
}
async function recursionRequest () {
    if (requestList.length) {
        let param = requestList[0]
        let requestStatus = false //当前接口请求状态
        if (!openRefresh) requestStatus = true
        if (param.data.param.refreshRequest) requestStatus = true //更新Token接口
        if (requestStatus) {
            openRefresh = true
            startRequest = true
            httpRrequest()
                .then((res) => {
                    if (param.data.param.refreshRequest) {
                        store.dispatch('setToken', res)
                        openRefresh = false
                    } else {
                        openRefresh = false
                    }
                    param.resolve(res)
                })
                .catch((error) => {
                    startRequest = false
                    openRefresh = false
                    param.reject(error)
                })
                .finally(() => {
                    if (!requestList.length) {
                        startRequest = false
                    } else {
                        recursionRequest()
                    }
                })
        }
    }
}

// 请求拦截器封装
function httpRrequest () {
    return new Promise((resolve, reject) => {
        let param = requestList.shift()
        const { data } = param
        let _url
        //请求地址  如果请求地址有https 或者有 http 则不添加公共请求头
        if (data && data.url) {
            if (
                data.url.indexOf('http://') < 0 &&
                data.url.indexOf('https://') < 0
            ) {
                _url = request_url + data.url
            } else {
                _url = data.url
            }
        } else {
            toast('请求地址不能为空')
            return
        }

        let _token = localStorage.getItem('token')

        if (data.auth && !_token) {
            //接口需要验证登录状态
            // store.dispatch('stateClear')
            // store.dispatch('temporaryClear')
            localStorage.clear()
            sessionStorage.clear()
            startRequest = false
            openRefresh = false
            // refreshToken = false
            requestList = []
            // goLogin()
            return
        }

        let requestParam = {
            url: _url,
            timeout: request_timeout * 1000, // 请求超时时间
            headers: {
                'content-type': 'application/json; charset=utf-8',

                // 'Access-Control-Allow-Origin': '*',
                // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE', // 允许的HTTP方法
                // 'Access-Control-Allow-Headers': 'Content-Type' // 允许的自定义请求头
            },
            data: data.param,
            method: data.method,
        }
        //发送请求
        axios(requestParam)
            .then(function (res) {
                if (res.status !== 200) {
                    toast(`服务器出现错误：错误代码：${res.status}`)
                    reject()
                    return
                }
                if (res.data.code != 1) {
                    startRequest = false
                    openRefresh = false
                    requestList = []
                    if ([101, 104, 105, 103].indexOf(res.data.code) > -1) {
                        store.dispatch('stateClear')
                        store.dispatch('temporaryClear')
                        localStorage.clear()
                        sessionStorage.clear()
                        // goLogin() // 需要重新登录
                    } else {
                        // reject(res.data.code)
                    }
                    toast(res.data.message)
                } else {
                    resolve(res.data.data)
                    // toast(res.data.message)
                }
            })
            .catch(function (error) {
                reject(error)
                toast(error)
            })
    })
}
