import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/index',
},

{
    path: "/login",
    name: "login",
    component: () =>
        import("@/views/login.vue"),
},
{
    path: '/',
    name: 'home',
    component: Home,
    children: [{
        path: "/index",
        name: "index",
        component: () =>
            import("@/views/index/index.vue"),
    },
    {
        path: "/school",
        name: "school",
        component: () =>
            import("@/views/school/index.vue"),
    },
    {
        path: "/school/detail",
        name: "schoolDetail",
        component: () =>
            import("@/views/school/detail.vue"),
    },
    {
        path: "/achievement",
        name: "achievement",
        component: () =>
            import("@/views/achievement/index.vue"),
    },
    {
        path: "/achievement/detail",
        name: "achievementDetail",
        component: () =>
            import("@/views/achievement/detail.vue"),
    },
    {
        path: "/achievement/add",
        name: "achievementAdd",
        component: () =>
            import("@/views/achievement/addNeed.vue"),
    },
    {
        path: "/release",
        name: "release",
        component: () =>
            import("@/views/release/index.vue"),
    },
    {
        path: "/release/detail",
        name: "releaseDetail",
        component: () =>
            import("@/views/release/detail.vue"),
    },
    {
        path: "/release/fabu",
        name: "fabu",
        component: () =>
            import("@/views/release/release.vue"),
    },
    {
        path: "/policy",
        name: "policy",
        component: () =>
            import("@/views/policy/index.vue"),
    },
    {
        path: "/policy/detail",
        name: "policydetail",
        component: () =>
            import("@/views/policy/detail.vue"),
    },
    {
        path: "/we",
        name: "we",
        component: () =>
            import("@/views/we/index.vue"),
    },
    {
        path: "/user",
        name: "user",
        component: () =>
            import("@/views/user/index.vue"),
    },
    ]
}
]

const router = new VueRouter({
    routes,
    //配置滚动行为，跳转到新的路由界面滚动条的位置
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})

export default router